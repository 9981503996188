import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../logo-two.png'

export default function Navbarthree() {
    const two = JSON.parse(localStorage.getItem('catrs'))
    return (
        <header>
            <nav>
                <Link to='/' onClick={() => window.scrollTo(0, 0)}>
                    <img className="navbarlogo" alt="logo" src={logo}></img>
                </Link>
                <div className='navs'>
                    <Link to="/cart" onClick={() => window.scrollTo(0, 0)}>
                        <button className='navbarbutton'>
                            Cart<h1>{two.length > 0 ? two.length : null}</h1>
                        </button></Link>
                </div>
            </nav>
        </header>
    )
}
