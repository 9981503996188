const data = {
    products: [
        {
            _id: '0',
            name: 'Frost Tee',
            category: 'Tees',
            image: '/images/white-tee-front.webp',
            imagetwo: '/images/white-tee-back.webp',
            size: true,
            slug: "frost-tee",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            price: 15919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- loose fit '
        },
        {
            _id: '1',
            name: 'Jade Tee',
            category: 'Tees',
            image: '/images/green-tee-front.webp',
            imagetwo: '/images/green-tee-back.webp',
            size: true,
            slug: "jade-tee",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            price: 19919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- loose fit '
        },
        {
            _id: '2',
            name: 'Wavy Blue Tee',
            category: 'Tees',
            image: '/images/blue-tee-front.webp',
            imagetwo: '/images/blue-tee-back.webp',
            size: true,
            slug: "wavy-tee",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            price: 23919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- loose fit '
        },
        {
            _id: '3',
            name: 'Lemon Tee',
            category: 'Tees',
            image: '/images/lime-tee-front.webp',
            imagetwo: '/images/lime-tee-back.webp',
            size: true,
            slug: "lemon-tee",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            price: 23919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- loose fit '
        },
        {
            _id: '4',
            name: 'Cherry Tee',
            category: 'Tees',
            image: '/images/pink-tee-front.webp',
            imagetwo: '/images/pink-tee-back.webp',
            size: true,
            slug: "cherry-tee",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            price: 19919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- loose fit '
        },
        {
            _id: '5',
            name: 'Tangerine',
            category: 'Tees',
            image: '/images/orange-tee-front.webp',
            imagetwo: '/images/orange-tee-back.webp',
            size: true,
            slug: "tangerine",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            price: 19919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- loose fit '
        },
        {
            _id: '6',
            name: 'Black Tee',
            category: 'Tees',
            image: '/images/black-tee-front.webp',
            imagetwo: '/images/black-tee-back.webp',
            size: true,
            slug: "black-tee",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            price: 15919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- loose fit '
        },
    ]
}
export default data;