import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ title, description, image }) => (
  <Helmet>
    <title>{title ? `${title} - ` : ''} 919DNR</title>
    <meta name="description" content={description ?? 'We are products of a controlled society'} data-rh="true"/>
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.919dnr.com/" />
    <meta property="og:title" content={title ? `${title} - 919DNR` : '919DNR'} />
    <meta property="og:description" content={description ?? 'We are products of a controlled society'} data-rh="true" />
    <meta name="twitter:type" content="website" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content="https://www.919dnr.com/" />
    <meta name="twitter:title" content={title ? `${title} - 919DNR` : '919DNR'} />
    <meta name="twitter:description" content={description ?? 'We are products of a controlled society'} data-rh="true"/>
  </Helmet>
);

export default MetaTags;
