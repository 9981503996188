import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../logo-two.png'

export default function Navbartwo(closeForm) {
    const two = JSON.parse(localStorage.getItem('catrs'))
    const carting = (e) => {
        e.preventDefault();
        closeForm(false)
        window.scrollTo(0, 0)
    }
    return (
        <header>
            <nav>
                <Link to='/' onClick={() => window.scrollTo(0, 0)}>
                    <img className="navbarlogo" alt="logo" src={logo}></img>
                </Link>
                <div className='navs'>
                    <Link to="/cart" onClick={(e) => carting(e)}>
                        <button className='navbarbutton'>
                            Cart<h1>{two.length > 0 ? two.length : null}</h1>
                        </button></Link>
                </div>
            </nav>
        </header>
    )
}
